import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDBContainer, MDBRow, MDBCol, MDBCardText, MDBCard, MDBCardBody } from 'mdbreact'
import moment from 'moment'
import ColumnAlternating from '../components/columnAlternatingStyled'

const EventsSection = ({ events }) => (
  <StaticQuery
    query={graphql`
      query EventsSectionQuery {
        events: allContentfulEvent(
          sort: { fields: date, order: DESC }
          limit: 3
        ) {
          edges {
            node {
              id
              slug
              title
              date
              heroSmall {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 600, height: 350, quality: 60)
                  }
                }
              }
              hero {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 600, height: 350, quality: 60)
                  }
                }
              }
              publishTo {
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
      {(data.events.edges && data.events.edges.length > 0) && 
      <>
        {data.events.edges[0].node.date >= moment(new Date()).format("YYYY-MM-DD") &&
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <h2 className="font-alt font-w-700 text-center letter-spacing-1 mt-4 mt-xl-0 mb-5 title-xs-medium title-large">
                  Upcoming UNICOM events
                </h2>
              </MDBCol>
            </MDBRow>

            {data.events.edges.length === 1 ?
              <>
                {data.events.edges.map(({ node }, index) => (
                  <ColumnAlternating
                    key={index}
                    title={`<span class="font-alt font-w-400 letter-spacing-1 mb-3 text-large">${moment(node.date).format('MMMM DD, YYYY - HH:mm')}</span> <br /><br /> ${node.title}`}
                    subtitle={node.subtitle}
                    image={node.heroSmall.localFile.childImageSharp.gatsbyImageData}
                    alt={node.title}
                    titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-small"
                    subtitleClass="font-w-400 letter-spacing-1 text-medium"
                    link={`${node.publishTo[0].url}/events/${node.slug}/`}
                    textCol={'7'}
                    imageCol={'5'}
                    colour="ficon-ug"
                  />
                ))}
              </>   
            :     
              <MDBRow className="pt-4">
                {data.events.edges.sort((a,b) => a.node.date == b.node.date ? 0 : a.node.date < b.node.date ? -1 : 1).map(({ node }, index) => (
                  <MDBCol key={index}>
                    <MDBCard className="flex-fill">
                      <GatsbyImage
                        image={node.heroSmall.localFile.childImageSharp.gatsbyImageData}
                        overlay="white-slight"
                        hover
                        waves
                        alt={node.title}
                        className="rounded-top drop-shadow"
                      />
                      <MDBCardBody>
                        <div style={{ minHeight: '150px', maxHeight: '150px' }}>
                          <p className="font-w-400 text-medium pt-2">
                              <a href={`${node.publishTo[0].url}/events/${node.slug}/`} className="effect" target="_blank" rel="noopener">
                              {node.title}
                              </a>
                          </p>
                        </div>
                        <hr />
                        <MDBCardText className={`font-w-400 mt-2 mb-2 `}>
                          {moment(node.date).format('MMM DD, YYYY')}
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
              </MDBRow>
            }
          </MDBContainer>
        </section>
        }
      </>
      }
      </>
    )}
  />
)
export default EventsSection
